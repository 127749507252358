import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const CatalogItemPage = () => (
	<Layout>
		<Seo title="Seventeen Oh-Two Oh-Six" />

		<h2>Seventeen Oh-Two Oh-Six</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST01 Seventeen Oh-Two Oh-Six front.jpg"
			alt="Jesse Rivest - Seventeen Oh-Two Oh-Six - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Words from Jesse</h3>
		<p>
			My first real, manufactured LP/CD!
			While touring and travelling in Australia,
			I developed the notion that sitting solo in front of some good mics in a studio room would result in a great CD;
			songs performed in live sets would make it authentic and intimate.
			I wanted to celebrate where I was at—imperfections, naiveties, and all!
			As a first big and real effort, I wanted it to achieve a lot—play on radio, book me gigs, win me fans, etc.—it was my ambitious ambassador.
			Ultimately, it was a cool little demo CD that I could use and sell;
			it marks a period of emergence, discovered freedom, and sovereignty in my life.
			I can still enjoy hearing and holding it now in 2011.
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>Mesmerize Me (3:53) <br /> US-CGJ-06-55439 - © Jesse Rivest 2006</li>
			<li>Worth the Wait (3:48) <br /> US-CGJ-06-55440 - © Jesse Rivest 2006</li>
			<li>Dragon &amp; Kitty (3:40) <br /> US-CGJ-06-55441 - © Jesse Rivest 2006</li>
			<li>Deadbeat Blues (4:47) <br /> US-CGJ-06-55442 - © Jesse Rivest 2006 *</li>
			<li>Crazy French Girl (7:00) <br /> US-CGJ-06-55443 - © Jesse Rivest 2006</li>
			<li>On the Cliffs Outside of Broome (3:12) <br /> US-CGJ-06-55444 - © Jesse Rivest 2006</li>
			<li>Do It Now (4:38) <br /> US-CGJ-06-55445 - © Jesse Rivest 2006</li>
			<li>Strong On My Own (2:49) <br /> US-CGJ-06-55446 - © Jesse Rivest 2006</li>
			<li>Turning Point (3:18) <br /> US-CGJ-06-55447 - © Jesse Rivest 2006</li>
			<li>Fremantle YHA Slide (4:03) <br /> US-CGJ-06-55448 - © Jesse Rivest 2006</li>
			<li>A Traveller's Song (5:36) <br /> US-CGJ-06-55449 - © Jesse Rivest 2006</li>
		</ol>
		<p>
			* Sorry for the explicit lyrics;
			by the way, this song was considered for the Best Newcomer category in the <a href="//www.webcitation.org/5YxJf2xsc" rel="external" title="2005 Calgary Folk Music Festival Songwriting Contest">2005 Calgary Folk Music Festival Songwriting Contest.</a><br />
			These ISRCs were generated by CD Baby (distributor) rather than www.jesserivest.com.
		</p>
		<p>
			This recording: catalog number JRIVEST01, © Jesse Rivest 2006, All Rights Reserved<br />
			UPC/EAN: 0829982090065<br />
			Released: May 10, 2006
		</p>

		<h3>Credits</h3>
		<p>
			Jesse Rivest sings and plays guitar; the production was his idea; the songs were performed in four live sets.<br />
			Recorded, then quickly mixed and mastered (time budget), by Josh Gwilliam with Jesse Rivest on February 17, 2006, at Music Centre Canada, Calgary, Alberta.<br />
			Design and layout by Jesse Rivest.
		</p>

		<h3>Extras</h3>
		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST01 Seventeen Oh-Two Oh-Six back.jpg"
			alt="Jesse Rivest - Seventeen Oh-Two Oh-Six - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>
		<h4>Physical copies</h4>
		<p>
			Manufactured, physical CDs are still available for sale! Just get in contact and I'll hook you up.
		</p>

		<h3 className="lyrics">Lyrics</h3>
		<div>
			<h4 className="lyrics__title">
				Mesmerize Me</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2006
			</h5>
			<p>
				I'd like to be with you tonight<br />
				You give me feelings I can't fight<br />
				If you were here you'd make things right
			</p>
			<p>
				I taste your lips in my mint tea<br />
				Your smile's stirred in with honey<br />
				And when I drink you refresh me
			</p>
			<p>
				Who's that knocking at my door?<br />
				I've got a fever of a hundred and four<br />
				Please be my baby with her sparkling eyes<br />
				Oooo, you mesmerize me
			</p>
			<p>
				My mind is soaked in you tonight<br />
				There's no drought for us in sight<br />
				Yes you're the one for me that's right
			</p>
			<p>
				You put the stars up in my sky<br />
				Yes you made me feel so high<br />
				If I come down I know I'll cry
			</p>
			<p>
				Who's that knocking at my door?<br />
				I've got a hunger for some more and some more<br />
				Please be my baby with her tender thighs<br />
				Mmmm, you mesmerize me
			</p>
			<p>
				Who's that knocking at my door?<br />
				I've got a fever of a hundred and four<br />
				Please be my baby with her sparkling eyes<br />
				Oooo, you mesmerize me
			</p>
			<p>
				Who's that coming back for more?<br />
				I'll give you pleasure once I open the door<br />
				Please be my baby with her sexy dreams<br />
				Oooo, you mesmerize me
			</p>
			<h4 className="lyrics__title">
				Worth the Wait
			</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2006
			</h5>
			<p>
				I fell in love with a beautiful girl<br />
				She went off to travel the world<br />
				I knew she'd leave ever since our first kiss<br />
				But I had to find out what I'd miss
			</p>
			<p>
				Don't tell me I'm crazy<br />
				The risk was worth the take<br />
				I know it sounds sad right now, but in her case<br />
				Love is worth the wait
			</p>
			<p>
				Her heart and dreams always guide her way<br />
				With strength and passion nearly every day<br />
				When she laughs her eyes are warm dancing flames<br />
				That boil courage through my veins
			</p>
			<p>
				Don't tell me I'm crazy<br />
				The risk was worth the take<br />
				I know it sounds sad right now, but in her case<br />
				Love is worth the wait
			</p>
			<p>
				Oh man I'm crazy for this girl<br />
				The risk was worth the take<br />
				My heart is stronger now that I'm learning
			</p>
			<p>
				Oh man I'm crazy for this girl<br />
				The risk was worth the take<br />
				My heart is stronger now that I'm learning<br />
				Love is worth the wait
			</p>
			<p>
				I would wait for you<br />
				Would you wait for me?<br />
				I would wait for you<br />
				Would you wait for me?
			</p>
			<h4 className="lyrics__title">
				Dragon &amp; Kitty</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2006
			</h5>
			<p>
				Baby I will keep my dragon, yes, I will keep my dragon<br />
				Babe I know you like my dragon and noone else is having it<br />
				I will keep my dragon for you to pet my dragon<br />
				Yes, I will keep my dragon for you
			</p>
			<p>
				Baby will you keep your kitty, yes, will you keep your kitty<br />
				Babe you know I like your kitty; I love to cuddle with it<br />
				Will you keep your kitty for me to pet your kitty<br />
				Yes, will you keep your kitty for me?
			</p>
			<p>
				When the kitty licks my finger, my finger wants to linger<br />
				And the dragon gets-a-jealous because he wants attention<br />
				You like to kiss the dragon; I like to kiss the kitty<br />
				Then the dragon and the kitty they dance
			</p>
			<p>
				Does the kitty miss the dragon like dragon misses kitty<br />
				Does the kitty miss the dragon and all the the things he does to her?<br />
				Dragon misses kitty that perfect little kitty<br />
				That makes my dragon wanna breathe fire
			</p>
			<p>
				Does the kitty miss the dragon like dragon misses kitty<br />
				Does the kitty miss the dragon and all the the things he does to her?<br />
				Dragon misses kitty that perfect little kitty<br />
				That makes my dragon wanna breathe fire
			</p>
			<p>
				When the kitty licks my finger, my finger wants to linger<br />
				And the dragon gets-a-jealous because he wants attention<br />
				You like to kiss the dragon; I like to kiss the kitty<br />
				Then the dragon and the kitty they dance
			</p>
			<h4 className="lyrics__title">
				Deadbeat Blues</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2006
			</h5>
			<p>
				I hate the fuckers downstairs<br />
				Those fuckers don't even care<br />
				They're just a couple of pricks<br />
				They're always shouting their shit<br />
				Their lease is up soon and I will gladly see them on their way
			</p>
			<p>
				Turn your shitty music down<br />
				I don't wanna hear another sound<br />
				Tell your deadbeat friends to leave<br />
				Or for them you will surely grieve<br />
				'Cause I've had enough of them and I will gladly put them in the ground
			</p>
			<p>
				Your front window used to have a screen<br />
				Start remembering your house keys<br />
				Learn to close the front door<br />
				There's no need to slam it like you do your whore<br />
				Speaking of her now, I wonder what ditch did you find her in?
			</p>
			<p>
				Your sofa's on the front lawn<br />
				Now I have to keep my front shades drawn<br />
				The guy next door can't sell his house<br />
				'Cause you fuckin' guys are always soused<br />
				Your too drunk to realize that you guys are embarrassing yourselves
			</p>
			<p>
				I hate those fuckers downstairs<br />
				Fuck—those guys don't even fuckin' care<br />
				They're always yelling their jive <br />
				They're dingy pad is a dive<br />
				Well, their lease is up soon and I will gladly see them on their way
				(Said I've had enough of them and I will gladly put them in the ground)
			</p>
			<h4 className="lyrics__title">
				Crazy French Girl</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2006
			</h5>
			<p>
				There's girls all around me<br />
				They think that they are free<br />
				They try to look so pretty<br />
				But their schemes backfire on me<br />
				I've got someone better<br />
				She's got that natural style<br />
				Her heart jumpstarts mine now<br />
				She could make the whole world smile<br />
				Yeah, whole world smile<br />
				Yeah
			</p>
			<p>
				I miss Crazy French Girl<br />
				She's the best I have seen<br />
				I love my crazy french girl<br />
				And I kiss her in my dreams<br />
				I miss Crazy French Girl<br />
				She's the best I have seen<br />
				I love my crazy french girl<br />
				And I kiss her in my dreams<br />
				Yeah, in my dreams<br />
				Yeah, in my dreams<br />
				Yeah, in my dreams<br />
				Yeah
			</p>
			<p>
				I don't care about money<br />
				Or material things<br />
				All I need is love girl<br />
				You'll watch my soul grow wings<br />
				I choose to live free and<br />
				In love I've set you free<br />
				If it's in your heart girl<br />
				Then you should wait for me<br />
				Yeah, wait for me<br />
				Yeah, wait for me<br />
				Yeah, wait for me<br />
				Yeah, wait for me<br />
				Yeah, wait for me<br />
				Yeah, wait for me<br />
				Yeah, wait for me
			</p>
			<h4 className="lyrics__title">
				On the Cliffs Outside of Broome</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2006
			</h5>
			<p>
				You are not the girl I used to know<br />
				The things you'd say and how you missed me so<br />
				The things you choose today I'll never know
			</p>
			<p>
				You are not the girl I love today<br />
				Where she is I wonder can you say?<br />
				You are not the girl I love today
				Now I'm moving on
			</p>
			<p>
				On the cliffs outside of Broome this morn<br />
				Sitting in the sun and feeling warm<br />
				How much time will pass before a storm
			</p>
			<p>
				You are not the girl I love today<br />
				In my memory is where she'll stay<br />
				If you were the girl I love today<br />
				Then you would come along<br />
				Now I'm moving on
			</p>
			<h4 className="lyrics__title">
				Do It Now</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2006
			</h5>
			<p>
				If I start saving now, I'll be in Montreal next summer<br />
				If I start planning now, I'll be down under next winter
			</p>
			<p>
				Only yesterday I had this feeling I was lost<br />
				Now since yesterday, I've had my wires uncrossed
			</p>
			<p>
				I'm gonna do it now—I'm gonna make it happen<br />
				Gonna do it now—I'm gonna get it started<br />
				Gonna do it now—I've got the get-up-go<br />
				I'm gonna do it now—I'm gonna take things real slow<br />
				And do it right<br />
				I'm gonna make it happen<br />
				It's gonna come in time
			</p>
			<p>
				If I stay focused, man, I could accomplish things a-plenty<br />
				If I keep practicing, I could be super good by thirty
			</p>
			<p>
				Only yesterday I had this feeling I was stuck<br />
				Now since yesterday, I've pulled myself from the muck
			</p>
			<p>
				I'm gonna do it now—I'm gonna make it happen<br />
				Gonna do it now—I'm gonna get it started<br />
				Gonna do it now—I've got the get-up-go<br />
				I'm gonna do it now—I'm gonna take things real slow<br />
				And do it right<br />
				I'm gonna make it happen<br />
				It's gonna come in time
			</p>
			<p>
				I'm gonna do it now—I'm gonna make it happen<br />
				Gonna do it now—I'm gonna get it started<br />
				Gonna do it now—I've got the get-up-go<br />
				I'm gonna do it now<br />
				I'm gonna do it now
			</p>
			<h4 className="lyrics__title">
				Strong On My Own</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2006
			</h5>
			<p>
				Girl when you left I cried for us<br />
				But there'll be time for us again<br />
				Your sexy lips I long to kiss<br />
				Oh the things that I miss
			</p>
			<p>
				Now I'm going without knowing if you'll wait for me<br />
				But I can't have my eyes leaking while waiting patiently<br />
				I will be strong on my own<br />
				I will be strong on my own
			</p>
			<p>
				Girl when you write I smile for us<br />
				And walk the day with my head high<br />
				Your dreaming eyes will speak with mine<br />
				Oh the things we shall say
			</p>
			<p>
				Now I'm going ever knowing that you cared for me<br />
				But I can't have you misreading that this is hurting me<br />
				I will be strong on my own<br />
				I will be strong on my own<br />
				And I will see you back home
			</p>
			<h4 className="lyrics__title">
				A Traveller's Song</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2006
			</h5>
			<p>
				Floating through the countryside like a butterfly<br />
				This breeze takes me for a ride—makes me feel so high
			</p>
			<p>
				Pausing—stop to rest a while—I've been gone so long<br />
				Each day sun comes up, goes down further from my home
			</p>
			<p>
				Reminiscing of those I have left behind<br />
				As my nose points to the sky<br />
				I wonder if I will return here one day<br />
				Will I feel this way again?
			</p>
			<p>
				Dancing like an open fire under starlit sky<br />
				Reach out radiating warmth—keep her by my side
			</p>
			<p>
				Naked on a sleepless night; we don't have much time<br />
				Under daylit mango trees we will say good-bye
			</p>
			<p>
				Dreaming of the places I have yet to see<br />
				As my nose points to the sky<br />
				Leaving things behind can only set me free<br />
				Will I see her face again?
			</p>
			<p>
				Will I see... will I feel this way?<br />
				Will I see her face again?
			</p>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
